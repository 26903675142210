<template>
  <div id="app">
    <ObjectDetector />
  </div>
</template>

<script>
import ObjectDetector from './components/ObjectDetector.vue'

export default {
  name: 'App',
  components: {
    ObjectDetector
  }
}
</script>